<template>
    <div>
        <h2>{{ $store.getters['app/GET_APP_MODE'] == 'create' ? "เพิ่ม" : "แก้ไข"}}ลิ้งค์ตัวแทน</h2>
        <v-form v-model="isFormValid">         
          <v-card class="mt-4">
              <v-card-title>ลิ้งค์ตัวแทน</v-card-title>

              <div class="pa-8">
                <v-form @submit.prevent="submitForm" v-model="isFormValid">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="formData.name"
                        outlined
                        label="ชื่อลิ้งค์ *"                               
                        :rules="[validators.required, validators.maxLengthValidator(formData.name, 50)]"
                        hide-details="auto"          
                        dense  
                        :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">                      
                      <delay-autocomplete
                        :url="'/inventory/shareablelink/warehouseList'"                
                        label="คลัง *"                    
                        outlined
                        v-model="formData.warehouse"
                        item-text="name"
                        item-value="id"
                        dense                
                        :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                        return-object
                        hide-details="auto" 
                        :rules="[validators.required]"
                      >
                      </delay-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">                      
                      <delay-autocomplete
                        :url="'/inventory/shareablelink/brandList'"                
                        label="แบรนด์ *"                    
                        outlined
                        v-model="formData.brands"
                        item-text="name"
                        item-value="id"
                        dense                
                        :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                        return-object
                        hide-details="auto" 
                        :rules="[validators.required]"
                        multiple
                      >
                      </delay-autocomplete>
                    </v-col>
                  </v-row>
                </v-form>                       
              </div>

              <v-card-actions>              
                <v-btn
                  color="primary"               
                  large          
                  :disabled="!isFormValid"
                  :loading="sendingData"
                  @click="submitForm"
                  v-if="$store.getters['app/GET_APP_MODE'] != 'read'"
                >
                  {{ $store.getters['app/GET_APP_MODE'] == 'create' ? "สร้าง" : "แก้ไข"}}
                </v-btn>

                <v-btn
                  color="secondary"               
                  outlined                 
                  large
                  :disabled="sendingData"
                  @click="$router.go(-1)"
                >
                  กลับ
                </v-btn>                
              </v-card-actions>
          </v-card>
        </v-form>
    </div>
</template>
<script>
import { asyncGet, asyncPostAsJson } from '@/helpers/asyncAxios'
import { maxLengthValidator, required } from '@core/utils/validation'
export default {
  data() {
    return {
      isFormValid: false,
      sendingData: false,
      formData: {
        name: '',
        warehouse: null,
        brands: [],
      },
      validators: {
        required,
        maxLengthValidator,
      },
    }
  },
  watch: {
    'formData.role': function (value) {
      if (!value) return

      const { id } = value

      this.formData.role_id = id
    },
  },
  async created() {
    const shareablelink_id = this.$route.params.shareablelink_id

    if (shareablelink_id == 'create') {
      this.$store.commit('app/SET_APP_MODE', 'create')
      return
    } else if (this.$store.getters['auth/GET_ABILITY'].can('update', 'Inventory'))
      this.$store.commit('app/SET_APP_MODE', 'edit')
    else this.$store.commit('app/SET_APP_MODE', 'read')

    this.$store.commit('app/SET_GLOBAL_LOADING', true)
    try {
      const { name, warehouse, brands } = await asyncGet('/inventory/shareablelink/' + shareablelink_id)

      this.formData = {
        name: name,
        warehouse: warehouse,
        brands: brands,
      }
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', this.$errorParser(error))
    }
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
  },
  methods: {
    async submitForm() {
      this.sendingData = true
      try {
        const shareablelink_id = this.$route.params.shareablelink_id

        const sendData = {
          ...this.formData,
          warehouse_id: this.formData.warehouse.id,
          brands: this.formData.brands.map(item => item.id),
        }

        let url = '/inventory/shareablelink'

        if (shareablelink_id != 'create') {
          sendData['_method'] = 'PUT'
          url += '/' + shareablelink_id
        }

        const { uuid } = await asyncPostAsJson(url, sendData)

        this.$router.push({
          name: 'shareablelink',
          query: {
            uuid: uuid,
          },
        })
      } catch (error) {
        console.log(error)
        this.$root.showCommonDialog('มีปัญหา', this.$errorParser(error))
      }

      this.sendingData = false
    },
  },
  components: {},
}
</script>